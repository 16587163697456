<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row mb-2">
      <div class="col-lg-12 d-none d-lg-block">
        <button class="btn btn-primary float-end" @click="showModal = true">
          <i class="bx bx-plus-circle align-middle me-1"></i> Ajouter une categorie de contravention
        </button>
      </div>
    </div>
    <b-modal v-model="showModal" title="Nouvelle Infraction" hide-footer>
      <form @submit.prevent="addContraventionType">

        <div class="form-group row mb-4">
          <label for="title" class="col-form-label col-lg-2">Titre</label>
          <div class="col-lg-10">
            <input v-model="data.nomType" id="titre" name="titre" type="text" class="form-control"
              placeholder="Entrez le nom de la contravention..." />
          </div>
        </div>

        <div class="form-group row mb-4">
          <label for="title" class="col-form-label col-lg-2">Code</label>
          <div class="col-lg-10">
            <input v-model="data.code" id="code" name="code" type="text" class="form-control"
              placeholder="Entrez le code de la contravention..." />
          </div>
        </div>

        <div class="form-group row mb-4">
          <label for="projectdesc" class="col-form-label col-lg-2">Description</label>
          <div class="col-lg-10">
            <textarea v-model="data.descriptionType" id="projectdesc" class="form-control" rows="4"
              placeholder="Donnez une desciption de le contravention..."></textarea>
          </div>
        </div>

        <div class="form-group row mb-4">
          <label for="title" class="col-form-label col-lg-2">Montant</label>
          <div class="col-lg-10">
            <input v-model="data.montant" id="montant" name="montant" type="text" class="form-control"
              placeholder="Entrez le montant de la contravention..." />
          </div>
        </div>

        <div class="text-end mt-3">
          <b-button variant="light" @click="showModal = false">Fermer</b-button>
          <b-button type="submit" variant="success" class="ms-1" :disabled="spanView">
            <span v-if="spanView" class="spinner-border spinner-border-sm"></span>
            Enregistrer
          </b-button>
        </div>
      </form>
    </b-modal>

    <b-modal v-model="showModal1" :title="ModalTitle" hide-footer>
      <form @submit.prevent="updateContraventionType">

        <div class="form-group row mb-4">
          <label for="title" class="col-form-label col-lg-2">Titre</label>
          <div class="col-lg-10">
            <input :disabled="inputsDisable" v-model="contTypeView.nomType" id="titre" name="titre" type="text" class="form-control"
              placeholder="Entrez le nom de la contravention..." />
          </div>
        </div>

        <div class="form-group row mb-4">
          <label for="title" class="col-form-label col-lg-2">Code</label>
          <div class="col-lg-10">
            <input :disabled="inputsDisable" v-model="contTypeView.code" id="code" name="code" type="text" class="form-control"
              placeholder="Entrez le code de la contravention..." />
          </div>
        </div>

        <div class="form-group row mb-4">
          <label for="projectdesc" class="col-form-label col-lg-2">Description</label>
          <div class="col-lg-10">
            <textarea :disabled="inputsDisable" v-model="contTypeView.descriptionType" id="projectdesc" class="form-control" rows="4"
              placeholder="Donnez une desciption de le contravention..."></textarea>
          </div>
        </div>

        <div class="form-group row mb-4">
          <label for="title" class="col-form-label col-lg-2">Montant</label>
          <div class="col-lg-10">
            <input :disabled="inputsDisable" v-model="contTypeView.montant" id="montant" name="montant" type="text" class="form-control"
              placeholder="Entrez le montant de la contravention..." />
          </div>
        </div>

        <div class="text-end mt-3" v-if="inputsDisable == false">
          <b-button variant="light" @click="showModal1 = false">Fermer</b-button>
          <b-button type="submit" variant="success" class="ms-1" :disabled="spanView">
            <span v-if="spanView" class="spinner-border spinner-border-sm"></span>
            Modifier
          </b-button>
        </div>
      </form>
    </b-modal>

    <div class="row">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">Liste des categories de contraventions</h4>

          <div class="row justify-content-between">


          </div>

          <div class="table-responsive mt-3">
            <table class="table table-nowrap table-centered mb-0 align-middle">
              <thead class="table-light">
                <tr>
                  <th scope="col">Contravention</th>
                  <th scope="col">Code</th>
                  <th scope="col">Montant</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="contra in contraData" :key=contra.id>
                  <td>{{ contra.nomType }}</td>
                  <td>{{ contra.code }}</td>
                  <td>{{ contra.montant }}</td>
                  <td>
                    <button class="btn btn-success btn-sm me-2 w-xs" @click="ContaventionType(contra,'Details Infration',true)">
                      Voir
                      <i class="mdi mdi-eye-outline"></i>
                    </button>
                    <button class="btn btn-primary btn-sm me-2 w-xs" @click="ContaventionType(contra,'Modifier Infration',false)">
                      Modifier
                      <i class="mdi mdi-pencil"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
// import { contraData } from "./contraData";
import { reqMethods } from '../../../state/helpers';
import useVuelidate from "@vuelidate/core";
import Swal from "sweetalert2";

/**
 * Lending component
 */
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    Layout, PageHeader,
  },
  data() {
    return {
      picked: "",
      daterange: "",
      contraData: {},
      contTypeView: {},
      ModalTitle: '',
      title: "Paramètre de contravention",
      items: [
        {
          text: "Dashboard",
          href: "/"
        },
        {
          text: "Contravention parametrage",
          active: true
        }
      ],
      data: {
        code: null,
        nomType: null,
        descriptionType: null,
        montant: null,
      },
      inputsDisable: false,
      spanView: false,
      showModal: false,
      showModal1: false,
      submitted: false,
      authError: null,
      response: null,
      fetchError: null,
    };
  },
  methods: {
    ...reqMethods,
    addContraventionType() {
      this.spanView = true;
      const that = this;
      this.postRequest({
        route: '/contravention/type-contravention/',
        data: this.data
      }).then(
        function (response) {
          that.spanView = false;
          that.data = {};
          Swal.fire(response.data.message, "La Contravention à été Ajouté!", "success");
          that.response = response.data;
          that.getAllContraventionTypes();
        },
        function (error) {
          that.spanView = false;
          that.authError = error;
          Swal.fire({
            title: "Oops...",
            text: "Erreur lors de l'ajout de la Contravention",
            icon: "error",
            confirmButtonClass: "btn btn-primary w-xs mt-2",
            buttonsStyling: false,
            showCloseButton: true,
          });
        }
      )
    },
    getAllContraventionTypes() {
      const that = this;
      this.getRequest('/contravention/type-contravention/')
      .then(
        function (response) {
          that.contraData = response.data;
        },
        function (error) {
          that.fetchError = error;
        }
      )
    },
    updateContraventionType() {
      this.spanView = true;
      const that = this;
      this.putRequest({
        route: '/contravention/type-contravention/',
        data: this.contTypeView
      }).then(
        function (response) {
          that.spanView = false;
          Swal.fire(response.data.message, "L'infraction à été Modifier!", "success");
          that.response = response.data;
          that.getAllContraventionTypes();
        },
        function (error) {
          that.spanView = false;
          that.authError = error;
          Swal.fire({
            title: "Oops...",
            text: "Erreur lors de la modification de l'infraction",
            icon: "error",
            confirmButtonClass: "btn btn-primary w-xs mt-2",
            buttonsStyling: false,
            showCloseButton: true,
          });
        }
      )
    },

    ContaventionType(contType,ModalTitle,inputsDisable){
      this.showModal1 = true;
      this.contTypeView = contType;
      this.ModalTitle = ModalTitle;
      this.inputsDisable = inputsDisable;
    }
    // searchFilter(e) {
    //   const searchStr = e.target.value;
    //   this.contraData = contraData.filter((contra) => {
    //     return (
    //       contra.author.toLowerCase().includes(searchStr.toLowerCase()) ||
    //       contra.contras.toLowerCase().includes(searchStr.toLowerCase()) ||
    //       contra.montant.toLowerCase().includes(searchStr.toLowerCase())
    //     );
    //   });
    // },
  },
  mounted() {
    this.getAllContraventionTypes();
  },
};
</script>